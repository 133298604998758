/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from "axios";
import queryString from "query-string";
import { AxiosInstance } from "../../shared/utils/axios";
const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID ?? "";
const OAUTH_SECRET = process.env.REACT_APP_OAUTH_SECRET ?? "";

const O_AUTH = {
  responseCode: "code",
  clientId: OAUTH_CLIENT_ID,
};

const ACCOUNTS_ENDPOINT = process.env.REACT_APP_ACCOUNTS_ENDPOINT ?? "";

const API_URL = process.env.REACT_APP_BASE_API_URL ?? "";

const CLIENTIDAUTHCODE_ENC = btoa(`${OAUTH_CLIENT_ID}:${OAUTH_SECRET}`);

const AUTH_HEADERS = {
  "Content-Type": "application/x-www-form-urlencoded",
  authorization: `Basic ${CLIENTIDAUTHCODE_ENC}`,
};

const clearAccessToken = (): void => {
  localStorage.removeItem("tokens");
};

export const logout = () => {
  clearAccessToken();
  window.location.href = `https://${ACCOUNTS_ENDPOINT}/exit?continue=${window.location.origin}`;
  return "Time for a nap. Logging out..";
};

const isAccessTokenAvailable = () => localStorage.getItem("tokens") !== null;

export const authService = (function () {
  const getOAuthUrl = (): string => {
    return `https://${ACCOUNTS_ENDPOINT}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${window.location.origin}`;
  };

  const performLogin = async (code: any) => {
    const authParams = {
      grant_type: "authorization_code",
      client_id: O_AUTH.clientId,
      redirect_uri: `${window.location.origin}`,
      code,
    };
    try {
      const res = await axios.post(
        `${API_URL}/oauth/token`,
        queryString.stringify(authParams as any),
        { headers: AUTH_HEADERS }
      );
      const tokens = res.data;
      localStorage.setItem("tokens", JSON.stringify(tokens));
      return tokens;
    } catch (e) {
      logout();
    }
  };

  const currentUser = async (): Promise<any> => {
    try {
      let hasLicenceAccess = false;
      const user = await checkLicenceAccess().then(async (data) => {
        const APP_ID = process.env.REACT_APP_ID ?? "";

        const isCarbonApp = data?.content.find((obj: any) => obj.id === APP_ID);
        const isVaild = isCarbonApp !== undefined;

        if (isVaild) {
          hasLicenceAccess = true;
          const res = await AxiosInstance.get("/v2/accounts/me", {
            baseURL: API_URL,
          });
          return {
            hasLicenceAccess,
            user: res.data,
          };
        }
        return { checkLicenceAccess, user: {} };
      });
      return user;
    } catch (e) {
      logout();
    }
  };

  const requestLicenceAccess = async () => {
    const res = await AxiosInstance.get("/v2/accounts/me", {
      baseURL: API_URL,
    });
    return await AxiosInstance.post("api/licensing", {
      userIds: [res.data.id],
    });
  };

  const checkLicenceAccess = async () => {
    try {
      const res: any = await AxiosInstance.get("/v2/store/apps", {
        baseURL: API_URL,
      });
      return res.data;
    } catch (e) {
      logout();
    }
  };
  return {
    performLogin,
    currentUser,
    isAccessTokenAvailable,
    getOAuthUrl,
    logout,
    requestLicenceAccess,
  };
})();
