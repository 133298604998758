import {
  FETCH_ORGANIZATIONS_LOADING,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  SET_CURRENT_ORG,
} from "../actions/ActionTypes";
export interface OrgReducerInterface {
  currentOrg: any;
  isFetchingCurrentOrg: boolean;
  currentOrgError: object;

  orgs: any[];
  isFetchingAllOrgs: boolean;
  orgsError: object;
}

export const orgReducer = (state: OrgReducerInterface, action: any): any => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_LOADING:
      return {
        ...state,
        isFetchingAllOrgs: true,
      };
    case FETCH_ORGANIZATIONS_SUCCESS: {
      const currentOrgId = action.payload.currentOrgId;
      const currentOrg = action.payload.data.find(
        (org: any) => org.id === currentOrgId
      );
      return {
        ...state,
        isFetchingAllOrgs: false,
        orgs: action.payload.data,
        currentOrg: currentOrg ?? state.currentOrg,
      };
    }
    case FETCH_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        isFetchingAllOrgs: false,
        orgsError: action.error,
      };
    }
    case SET_CURRENT_ORG: {
      return {
        ...state,
        currentOrg: action.payload,
      };
    }
    default:
  }
};
