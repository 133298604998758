import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { type ReactNode } from "react";

interface ConfirmationPopupProps {
  content: ReactNode;
  title: string;
  onOk: () => void;
  cancelText?: string;
  okText?: string;
}

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}
interface NotificationProp {
  type: NotificationType;
  description?: string;
  message: string;
  duration?: number;
}

export const DeleteConfirmationPopup = ({
  content,
  title,
  onOk,
  cancelText = "Go Back",
  okText = "Delete",
}: ConfirmationPopupProps): void => {
  Modal.confirm({
    title,
    content,
    cancelText,
    okText,
    onOk: () => {
      onOk();
    },
    icon: <ExclamationCircleOutlined style={{ color: "#CF3537" }} />,
    okType: "danger",
    okButtonProps: {
      style: { backgroundColor: "#CF3537", color: "white" }, // Customize background color and text color
    },
  });
};

export const openNotificationWithIcon = ({
  type,
  message,
  description,
  duration = 3,
}: NotificationProp): void => {
  notification[type]({
    description,
    placement: "topRight",
    duration,
    message,
  });
};
