import { useContext, type ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GlobalContext } from "../context/AppContextProvider";
import { Assessments } from "./assessments/Assessments";
import Custom from "./assessments/custom/Custom";
import NotFound from "./not-found";
import { Organization } from "./organization/Organizations";

export const AppRoutes = (): ReactElement | null => {
  const { orgState } = useContext(GlobalContext);
  const redirectToAssessmets = (): string => {
    const orgId = orgState?.currentOrg?.id;
    return orgId ? `/app/organization/${orgId as string}/assessments` : "/app";
  };
  return (
    <Routes>
      <Route path="/app/">
        <Route path="" element={<Organization />} />
        <Route
          path="assessments"
          element={<Navigate to={redirectToAssessmets()} />}
        />
        <Route path="organization/:orgId">
          <Route path="assessments" element={<Assessments />} />
          <Route path="assessment/create">
            <Route path="custom" element={<Custom />} />
            <Route path="custom/:customStep" element={<Custom />} />
            <Route key="not-found" path="" element={<NotFound />} />
          </Route>
          <Route path="assessment/edit/:assessmentId">
            <Route path="custom" element={<Custom />} />
            <Route path="custom/:customStep" element={<Custom />} />
            <Route key="not-found" path="" element={<NotFound />} />
          </Route>
        </Route>
      </Route>

      <Route path="/" element={<Navigate replace to="/app" />} />
      <Route key="not-found" path="*" element={<NotFound />} />
    </Routes>
  );
};
