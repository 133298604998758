import { ConfigProvider } from "antd";
import React, { type ReactNode } from "react";
import { AntdConfigProvider } from "syngenta-digital-cropwise-react-ui-kit";
interface IThemeProviderProps {
  themeName?: string;
  children: ReactNode;
}
export const ThemeProvider: React.FC<IThemeProviderProps> = ({
  children,
  themeName,
}) => (
  <AntdConfigProvider prefixCls="syngenta-ant">
    <ConfigProvider>{children}</ConfigProvider>
  </AntdConfigProvider>
);
