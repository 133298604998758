import {
  Application,
  AssessmentSteps,
  PracticesKeys,
  SoilCharacteristicsKeys,
  YieldKeys,
} from "../../constants/Assesments";

interface StepState {
  panelKeys: string[];
  activePanelKeys: string[];
  isExpandedAll: boolean;
}
export type AssessmentStepsTypes =
  | "basic-info"
  | "applications"
  | "practices"
  | "soil-characteristics"
  | "yield";

export type StepsStateProps = Record<AssessmentStepsTypes, StepState> &
  CurrentStep;

interface EnebledSteps {
  applications: boolean;
  practices: boolean;
  soilCharacteristics: boolean;
  yield: boolean;
}
interface CurrentStep {
  currentStep: number;
  enabledSteps: EnebledSteps;
}

const applicationKeys = Object.values(Application);
const practicesKeys = Object.values(PracticesKeys);
const soilKeys = Object.values(SoilCharacteristicsKeys);
const yieldKeys = Object.values(YieldKeys);

export const assesmentStepsInitialState: StepsStateProps = {
  currentStep: 0,
  enabledSteps: {
    applications: false,
    practices: false,
    soilCharacteristics: false,
    yield: false,
  },
  [AssessmentSteps.BASIC_INFO]: {
    isExpandedAll: true,
    panelKeys: [],
    activePanelKeys: [],
  },
  [AssessmentSteps.APPLICATIONS]: {
    isExpandedAll: true,
    panelKeys: applicationKeys,
    activePanelKeys: applicationKeys,
  },
  [AssessmentSteps.PRACTICES]: {
    isExpandedAll: true,
    panelKeys: practicesKeys,
    activePanelKeys: practicesKeys,
  },
  [AssessmentSteps.SOIL_CHARACTERISTICS]: {
    isExpandedAll: true,
    panelKeys: soilKeys,
    activePanelKeys: soilKeys,
  },
  [AssessmentSteps.YIELD]: {
    isExpandedAll: true,
    panelKeys: yieldKeys,
    activePanelKeys: yieldKeys,
  },
};
