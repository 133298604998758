import {
  FETCH_ASSESSMENT_BY_ID_ERROR,
  FETCH_ASSESSMENT_BY_ID_LOADING,
  FETCH_ASSESSMENT_BY_ID_SUCCESS,
} from "../actions/ActionTypes";

export interface IAssessmentReducer {
  currentAssessment: any;
  isFetchingCurrentAssessment: boolean;
  currentAssessmentError: any;

  assessments: any[];
  isFetchingAllAssessments: boolean;
  assessmentsError: any;
}

export const assessmentReducer = (
  state: IAssessmentReducer,
  action: any
): any => {
  switch (action.type) {
    case FETCH_ASSESSMENT_BY_ID_LOADING:
      return {
        ...state,
        isFetchingCurrentAssessment: true,
      };
    case FETCH_ASSESSMENT_BY_ID_SUCCESS:
      return {
        ...state,
        isFetchingCurrentAssessment: false,
        currentAssessment: action.payload,
      };
    case FETCH_ASSESSMENT_BY_ID_ERROR: {
      return {
        ...state,
        isFetchingCurrentAssessment: false,
        currentAssessmentError: action.error,
      };
    }
    default:
      return state;
  }
};
