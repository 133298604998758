import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, {
  useContext,
  useEffect,
  type FC,
  type ReactElement,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/AppContextProvider";
import { fetchFarmsAndFieldsByOrgId } from "../../context/actions/FarmsAndFields";
import { InlineLoader } from "../../shared/components/Loader";
import "./styles.less";

const { Panel } = Collapse;
interface ExpandIcon {
  isActive?: boolean;
}
const CollapseExpandIcon: FC<ExpandIcon> = ({
  isActive = false,
}): ReactElement => {
  return (
    <>
      {isActive ? (
        <UpOutlined style={{ fontSize: "10px", color: "#696F88" }} />
      ) : (
        <DownOutlined style={{ fontSize: "10px", color: "#696F88" }} />
      )}
    </>
  );
};
interface FormsAndFieldsProps {
  onChange: (key: string | string[]) => void;
  farms: any[];
}

const CustomExpandIcon = (panelProps: any): React.ReactNode => {
  const isActive = panelProps.isActive;
  return <CollapseExpandIcon isActive={isActive} />;
};

const FormsAndFields: FC<FormsAndFieldsProps> = ({
  onChange,
  farms,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Collapse
      rootClassName="forms-menu__collapse"
      onChange={onChange}
      expandIconPosition="end"
      expandIcon={CustomExpandIcon}
    >
      {farms?.map(({ name, fields }, index: number) => (
        <Panel
          className="forms-menu__collapse__panel"
          header={
            <span className="headerText" style={{ fontWeight: "600" }}>
              {t(name)}
            </span>
          }
          key={String(index + 1)}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ul style={{ marginLeft: "0", paddingLeft: "16px" }}>
              {fields?.map((field: any) => (
                <li key={field.id}>{t(field.name)}</li>
              ))}
            </ul>
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

const FormsAndFieldsMenu: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const { farmsAndFieldsState, farmsAndFieldsDispatch } = context;
  const { orgId } = useParams();

  useEffect(() => {
    void fetchFarmsAndFieldsByOrgId(farmsAndFieldsDispatch, orgId as string);
  }, [orgId]);

  const { farms, isFetchingFarms } = farmsAndFieldsState;

  const onChange = (key: string | string[]): void => {};
  return (
    <div className="forms-menu">
      <h1 className="forms-menu__heading">{t("Available Farms and Fields")}</h1>
      {isFetchingFarms && <InlineLoader />}
      {!isFetchingFarms && (
        <>
          {farms.length > 0 ? (
            <FormsAndFields farms={farms} onChange={onChange} />
          ) : (
            <div style={{ textAlign: "center" }}>
              {t("No Farms for this Org")}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FormsAndFieldsMenu;
