import React, {
  useMemo,
  useReducer,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
} from "react";
import {
  assesmentStepsInitialState,
  type AssessmentStepsTypes,
  type StepsStateProps,
} from "./initialStates/assesmentStepsState";
import assessmentsInitialState from "./initialStates/assessmentsInitialState";
import farmsAndFieldsInitialState from "./initialStates/farmsAndFieldsInitialState";
import orgnizationInitialState from "./initialStates/orgnizationInitialState";
import {
  assessmentStepsReducer,
  type ActionType,
} from "./reducers/assessmentStepsReducer";
import {
  assessmentReducer,
  type IAssessmentReducer,
} from "./reducers/assessments";
import {
  farmsAndFieldsReducer,
  type FarmsAndFieldsReducerInterface,
} from "./reducers/farmsAndFields";
import { orgReducer, type OrgReducerInterface } from "./reducers/orgnization";
export type OtherPages = "providers";
export interface IGenericMetadata {
  appGenericMetadata: Map<AssessmentStepsTypes | OtherPages, any>;
  setAppGenericMetadata?: Dispatch<SetStateAction<Map<any, any>>>;
}
export interface GlobalContextInterface extends IGenericMetadata {
  orgState?: OrgReducerInterface;
  orgDispatch?: any;
  assessmentState?: IAssessmentReducer;
  assessmentDispatch?: any;
  assessmentStepsState: StepsStateProps;
  assessmentStepsDispatch: React.Dispatch<ActionType>;
  farmsAndFieldsState: FarmsAndFieldsReducerInterface;
  farmsAndFieldsDispatch?: any;
}

const AppContextInitialData: GlobalContextInterface = {
  assessmentStepsState: assesmentStepsInitialState,
  assessmentStepsDispatch: (action: ActionType) => {},
  farmsAndFieldsState: farmsAndFieldsInitialState,
  appGenericMetadata: new Map<
    "applications" | "practices" | "soil-characteristics" | "yield",
    any
  >(),
};

export const GlobalContext = React.createContext<GlobalContextInterface>(
  AppContextInitialData
);

export const GlobalProvider = (props: any): ReactElement => {
  const [orgState, orgDispatch] = useReducer(
    orgReducer,
    orgnizationInitialState
  );
  const [assessmentState, assessmentDispatch] = useReducer(
    assessmentReducer,
    assessmentsInitialState
  );
  const [assessmentStepsState, assessmentStepsDispatch] = useReducer(
    assessmentStepsReducer,
    assesmentStepsInitialState
  );

  const [farmsAndFieldsState, farmsAndFieldsDispatch] = useReducer(
    farmsAndFieldsReducer,
    farmsAndFieldsInitialState
  );
  const [appGenericMetadata, setAppGenericMetadata] = useState(new Map());

  const globalContextProviderValue = useMemo(
    () => ({
      orgState,
      orgDispatch,
      assessmentState,
      assessmentDispatch,
      assessmentStepsState,
      assessmentStepsDispatch,
      farmsAndFieldsState,
      farmsAndFieldsDispatch,
      appGenericMetadata,
      setAppGenericMetadata,
    }),
    [
      orgState,
      orgDispatch,
      assessmentState,
      assessmentDispatch,
      assessmentStepsState,
      assessmentStepsDispatch,
      farmsAndFieldsState,
      farmsAndFieldsDispatch,
      appGenericMetadata,
      setAppGenericMetadata,
    ]
  );

  return (
    <GlobalContext.Provider value={globalContextProviderValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};
