import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/AppContextProvider";
import { ErrorDisplay } from "../../shared/components/Error";
import { useNavigate } from "react-router-dom";
import { type OrgReducerInterface } from "../../context/reducers/orgnization";
import { SET_CURRENT_ORG } from "../../context/actions/ActionTypes";
export const Organization = (): JSX.Element => {
  const context = useContext(GlobalContext);
  const { orgDispatch, orgState } = context;
  const navigate = useNavigate();
  const { orgs } = orgState as OrgReducerInterface;
  useEffect(() => {
    const selectedOrgId = localStorage.getItem("OrganizationId");
    const fetchOrgsAndFindSelected = async (): Promise<void> => {
      if (selectedOrgId) {
        const selectedOrg = orgs.find((org) => org.id === selectedOrgId);
        if (selectedOrg) {
          orgDispatch({ type: SET_CURRENT_ORG, payload: selectedOrg });
          navigate(`/app/organization/${selectedOrgId}/assessments`);
        }
      }
    };
    void fetchOrgsAndFindSelected();
  }, [orgs]);
  return (
    <ErrorDisplay
      title={"No Organization selected"}
      subTitle={
        "In order to create a custom assessment,\n you have to select an organization"
      }
      cardClassName="custom-card"
    ></ErrorDisplay>
  );
};
