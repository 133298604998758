import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
interface ILoader {
  mask: boolean;
  message?: string;
}

export const Loader: FC<ILoader> = ({ message, mask }): ReactElement => {
  const { t } = useTranslation();
  return (
    <div>
      {mask && <div className="mask" />}
      <div className="loader-wrapper">
        <div className="loader-container">
          <div className="loader" />
          <div className="loader-text">{t(message ?? "Loading ...")}</div>
        </div>
      </div>
    </div>
  );
};

export const InlineLoader: FC<{ padding?: boolean }> = ({ padding }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#a3dfa5" }} spin />
  );
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: !(padding ?? false) ? "" : "150px",
        fontStyle: "oblique",
        fontSize: "16px",
        color: "#747778",
        fontFamily: "cursive",
      }}
    >
      <p>
        <Spin indicator={antIcon} />
      </p>
    </div>
  );
};
