import { Dropdown } from "antd";
import { type FC, type ReactElement } from "react";
import { Avatar, TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";
import { useAuth } from "../../lib/auth/AuthContext";
import Organizations from "./Organizations";
import "./header.less";
import { type Profile as IProfile } from "./types";

const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.length > 0 ? name.split(" ")[1] : " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const ProfileOverlay = (): JSX.Element => {
  const { user, logout, setLogoutStatus } = useAuth();
  return (
    <div data-cy="profileOverlay" className="logoutOverlayBlock">
      <div className="profileInfo">
        <h3>{"User Profile"}</h3>
        <div className="profileUserData">
          <span>{user.name}</span>
        </div>
      </div>
      <button
        className="logoutLink"
        onClick={() => {
          setLogoutStatus(true);
          logout();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setLogoutStatus(true);
            logout();
          }
        }}
      >
        <span>{"Log out"}</span>
      </button>
    </div>
  );
};

const Profile: FC<IProfile> = ({ initials }) => {
  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={ProfileOverlay}
      placement="topRight"
    >
      <button
        data-cy="profile-icon"
        className="ant-dropdown-link"
        onClick={(e) => {
          e.preventDefault();
        }}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      >
        <Avatar shape="circle" size={40}>
          {initials}
        </Avatar>
      </button>
    </Dropdown>
  );
};
const Organisation = (): JSX.Element => <Organizations />;
export const GlobalHeader: FC = (): ReactElement => {
  const { user } = useAuth();
  return (
    <TopNavBar
      navbarIcon={Organisation}
      newDesign={true}
      profileDropDown={<Profile initials={getInitials(user.name)} />}
    />
  );
};
