import { useContext, type FC, type ReactElement } from "react";
import CFTIcon from "../../assets/images/cftImage.jpg";
import SyngentaLogo from "../../assets/images/SyngentaLogo.svg";
import { GlobalContext } from "../../context/AppContextProvider";
export const GlobalFooter: FC = (): ReactElement => {
  const { assessmentState } = useContext(GlobalContext);
  const { currentAssessment } = assessmentState ?? {};

  const { provider } = currentAssessment || {};
  const isCFTProvider = provider?.includes("CFT");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderTop: "1px solid #DFE2E7",
      }}
    >
      <h1 style={{ color: "#868CA2", fontSize: "12px" }}>
        Copyright © 2023 - Syngenta Digital. All Rights Reserved.
      </h1>
      <div style={{ display: "flex", gap: "16px" }}>
        {provider && (
          <img
            src={isCFTProvider ? CFTIcon : ""}
            alt=""
            width={"90.312px"}
            height={"32px"}
          />
        )}
        <img src={SyngentaLogo} alt="" />
      </div>
    </div>
  );
};
