import React from "react";
import ReactDOM from "react-dom/client";
import "syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less";
import App from "./App";
import AppProviders from "./context";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import ConfigMissing from "./shared/config-missing";
const isConfigurationGood = !!(
  process.env.REACT_APP_ENV !== null &&
  process.env.REACT_APP_ACCOUNTS_ENDPOINT !== null &&
  process.env.REACT_APP_BASE_API_URL !== null &&
  process.env.REACT_APP_API_URL !== null &&
  process.env.REACT_APP_OAUTH_CLIENT_ID !== null
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    {isConfigurationGood ? (
      <AppProviders>
        <App />
      </AppProviders>
    ) : (
      <ConfigMissing />
    )}
  </>
);

reportWebVitals();
