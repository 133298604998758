import { type Dispatch, type ReactNode, type SetStateAction } from "react";

export interface FilterContentProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export interface AssesmentWrapperProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  titleFontSize?: 12 | 14 | 16 | 18 | 20 | 22;
}

export interface Crop {
  id: string;
  name: string | null;
}
export interface CropCycle {
  id: string;
  name: string;
  crop: Crop;
  endDate: string;
  startDate: string;
}
export const AssessmentStatuses = {
  initiated: "In progress",
  inDraft: "In Draft",
  inprogress: "In progress",
  completed: "Completed",
  error: "Failed",
};
export interface Pagination {
  limit: number;
  page: number;
  sortType: "desc" | "asc";
  sortBy: string;
}
export interface AssessmentType {
  id: string;
  createdAt: string;
  assessmentId: string;
  orgId: string;
  assessmentStatus: string;
  cropId: string;
  cropName: string;
  cropCycleId: string;
  cropCycleName: string;
  cropCycleStartDate: string;
  cropCycleEndDate: string;
  provider: string[];
  coolFarmTool: any;
}
export interface PaginatedAssesments {
  totalDocs: number;
  assessments: AssessmentType[];
}
export interface AssessmentResponce extends Pagination {
  docs: AssessmentType[];
}
