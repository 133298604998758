/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * https://kentcdodds.com/blog/authentication-in-react-applications
 * https://www.digitalocean.com/community/tutorials/react-manage-user-login-react-context
 */
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { Loader } from "../../shared/components/Loader";
import { authService } from "./AuthService";

interface AuthInterface {
  user?: any;
  logoutStatus?: boolean;
  error?: any;
  setLogoutStatus?: any;
  status?: string;
  hasLicenceAccess: boolean;
}

const AUTH_META: AuthInterface = {
  status: "idle",
  error: null,
  user: null,
  hasLicenceAccess: false,
};

const AuthContext = React.createContext<AuthInterface>(AUTH_META);
let isApiCalled = false;

function AuthProvider(props: any) {
  const [logoutStatus, setLogoutStatus] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<{
    status: string;
    errorMsg: string;
  }>({ status: "idle", errorMsg: "" });
  const [userFetchError, setUserFetchError] = useState<string>();
  const [user, setUser] = useState<any>({});
  const [hasLicenceAccess, setHasLicenceAccess] = useState<boolean>(true);

  const login = async (code: string) => {
    try {
      setAuthStatus({ status: "loading", errorMsg: "" });
      const token = await authService.performLogin(code);
      if (token) {
        setAuthStatus({ status: "Success", errorMsg: "" });
        getCurrentUser();
      }
    } catch (e) {
      setAuthStatus({ status: "error", errorMsg: "Something Went Wrong" });
    }
  };

  const getCurrentUser = async () => {
    const { user, hasLicenceAccess } = await authService.currentUser();
    setHasLicenceAccess(hasLicenceAccess);
    if (user) {
      setAuthStatus({ status: "", errorMsg: "" });
      setUser(user);
    } else {
      setUserFetchError("Error in Fetching User");
    }
  };

  const query = queryString.parse(window.location.search);

  useEffect(() => {
    if (Boolean(query?.code) && !isApiCalled) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      if (!sessionStorage.getItem(`cod${query.code}`)) {
        isApiCalled = true;
        login(query.code as string);
      }
    } else if (authService.isAccessTokenAvailable()) {
      getCurrentUser();
    } else {
      window.location.href = authService.getOAuthUrl();
    }
  }, []);

  const { status, errorMsg } = authStatus;

  const authContextProviderValue = useMemo(
    () => ({
      status,
      logoutStatus,
      setLogoutStatus,
      user,
      error: errorMsg || userFetchError,
      hasLicenceAccess,
    }),
    [
      status,
      logoutStatus,
      setLogoutStatus,
      user,
      errorMsg,
      userFetchError,
      hasLicenceAccess,
    ]
  );

  return (
    <AuthContext.Provider value={authContextProviderValue}>
      {["loading", "idle"].includes(status) ? (
        <Loader mask={true} message={`${"Authenticating..."}`} />
      ) : (
        <>
          {userFetchError ?? (status === "error" && errorMsg) ? (
            <Loader mask={true} message="Error..." />
          ) : (
            props.children
          )}
        </>
      )}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  const isPending = ["loading", "idle"].includes(context.status ?? "");
  const isError = context.status === "error";
  const isSuccess = context.status === "success";
  const isAuthenticated = Boolean(context.user) && isSuccess;
  return {
    ...context,
    logout: authService.logout,
    logoutStatus: context.logoutStatus,
    setLogoutStatus: context.setLogoutStatus,
    isPending,
    isSuccess,
    isError,
    isAuthenticated,
  };
}

export { AuthProvider, useAuth };
