import { useContext, useEffect, type ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../context/AppContextProvider";
import { SET_CURRENT_ORG } from "../../context/actions/ActionTypes";
import { fetchAllOrgs } from "../../context/actions/orgAction";
import { type OrgReducerInterface } from "../../context/reducers/orgnization";
import { InlineLoader } from "../components/Loader";
import PopoverForSearch from "../components/PopoverForSearch";
import { TitleAndSubTitle } from "../components/Typography";

const Organizations = (): ReactElement => {
  const context = useContext(GlobalContext);
  const { orgDispatch, orgState } = context;

  const navigate = useNavigate();
  const onChangeHandler = (id: string): void => {
    const currentOrg = orgs.find((org) => org.id === id);
    orgDispatch({ type: SET_CURRENT_ORG, payload: currentOrg });
    localStorage.setItem("OrganizationId", id);
    navigate(`/app/organization/${id}/assessments`);
  };

  const { orgs, currentOrg, isFetchingAllOrgs } =
    orgState as OrgReducerInterface;

  useEffect(() => {
    const currentOrgId = location.pathname.split("/")[3];
    void fetchAllOrgs(orgDispatch, currentOrgId);
  }, []);

  return (
    <>
      {isFetchingAllOrgs ? (
        <InlineLoader />
      ) : (
        <PopoverForSearch
          currentItemId={currentOrg.id}
          onChangeHandler={onChangeHandler}
          totalItems={orgs}
        >
          <div style={{ display: "flex" }}>
            <TitleAndSubTitle
              subTitleFontSize={12}
              titleFontSize={14}
              className="organization"
              title={currentOrg.name ?? "Select an organization"}
              subTitle="Organization"
            />
            <DownOutlined style={{ alignSelf: "end", marginLeft: 16 }} />
          </div>
        </PopoverForSearch>
      )}
    </>
  );
};

export default Organizations;
