import { type Dispatch, type SetStateAction } from "react";
import {
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING,
  FETCH_ORGANIZATIONS_SUCCESS,
} from "./ActionTypes";
import AxiosInstance from "../../shared/utils/axios";

export const fetchAllOrgs = async (
  dispatch: Dispatch<SetStateAction<object>>,
  currentOrgId?: string
): Promise<any> => {
  dispatch({ type: FETCH_ORGANIZATIONS_LOADING });
  try {
    const response = await AxiosInstance.get("/api/orgs");
    dispatch({
      type: FETCH_ORGANIZATIONS_SUCCESS,
      payload: { data: response.data, currentOrgId },
    });
    return response;
  } catch (error) {
    dispatch({ type: FETCH_ORGANIZATIONS_ERROR, error });
  }
};
