import { Layout } from "antd";
import { type FC, type ReactNode } from "react";
import { ErrorBoundary } from "../components/ErrorBoundary";
interface IPagelayout {
  children: ReactNode;
}
export const PageLayout: FC<IPagelayout> = ({ children }) => {
  return (
    <Layout
      style={{
        height: "100%",
        minHeight: "100%",
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Layout>
  );
};
