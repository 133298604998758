import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "syngenta-digital-cropwise-react-ui-kit";
import Icon from "../../assets/images/Icon.svg";
import expandedIcon from "../../assets/images/expandedIcon.svg";
import "./sidebar.less";
interface Route {
  name: string;
  path: string;
}

export const SidebarMenu: React.FC = (props) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname.split("/");
  const isAssessment = window.location.pathname
    .toString()
    .includes("assessment")
    ? "ASSESSMENTS"
    : "";
  const [active, setActive] = useState(isAssessment);

  const currentPage = pathName[pathName.length - 1];
  const routes: Record<string, Route> = {
    ASSESSMENTS: {
      name: "Assessments",
      path: "/assessments",
    },
    // FORM_PRACTICES: {
    //   name: "Farm Practices",
    //   path: "/farm-practices",
    // },
  };
  const routesMap = Object.keys(routes);
  return (
    <Sidebar
      collapsedIcon={
        <img className="collapse-icon" alt={"collapse-con"} src={Icon} />
      }
      expandedIcon={
        <img className="expand-icon" alt={"expand-con"} src={expandedIcon} />
      }
      data-cy="sidebar"
      theme="dark"
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      selectedKeys={[active || currentPage.toUpperCase()]}
      collapsedMenu={false}
      items={routesMap.map((route) => ({
        key: route,
        label: routes[route].name,
        icon: (
          <img
            className="sidebar-icon active"
            alt={route.toLowerCase()}
            src={require(`../../assets/images/${route
              .toLowerCase()
              .replaceAll("_", "-")}.svg`)}
          />
        ),
        onClick: async (e: any) => {
          setActive(route);
          navigate(`/app${routes[route].path}`);
        },
      }))}
    />
  );
};
