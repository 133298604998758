import { type FC } from "react";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import { type ContentLoader as IContentLoader } from "./types";

export const ContentLoader: FC<IContentLoader> = ({
  children,
}): JSX.Element => {
  return (
    <Layout className="page-content" id="scroll">
      {children}
    </Layout>
  );
};
