import { type Dispatch, type SetStateAction } from "react";
import AxiosInstance from "../../shared/utils/axios";
import {
  FETCH_FARMS_AND_FIELDS_ERROR,
  FETCH_FARMS_AND_FIELDS_LOADING,
  FETCH_FARMS_AND_FIELDS_SUCCESS,
} from "./ActionTypes";

export const fetchFarmsAndFieldsByOrgId = async (
  dispatch: Dispatch<SetStateAction<object>>,
  orgId: string
): Promise<any> => {
  dispatch({ type: FETCH_FARMS_AND_FIELDS_LOADING });
  try {
    const response = await AxiosInstance.get(`/api/farms?orgId=${orgId}`);
    dispatch({ type: FETCH_FARMS_AND_FIELDS_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: FETCH_FARMS_AND_FIELDS_SUCCESS, payload: [] });
    dispatch({ type: FETCH_FARMS_AND_FIELDS_ERROR, error });
  }
};
