import { AssessmentSteps } from "../../constants/Assesments";
import { ASSESMENT_STEPS_ACTIONS } from "../actions/ActionTypes";
import { type StepsStateProps } from "../initialStates/assesmentStepsState";

export interface ActionType {
  type: ASSESMENT_STEPS_ACTIONS;
  payload: any;
}
export const assessmentStepsReducer = (
  state: StepsStateProps,
  action: ActionType
): StepsStateProps => {
  const {
    UPDATE_APPLICATION,
    UPDATE_CURRENT_STEP,
    UPDATE_PRACTICES,
    UPDATE_SOIL_CHARACTERISTICS,
    UPDATE_ENABLE_STEPS,
    UPDATE_YIELD_INFO,
  } = ASSESMENT_STEPS_ACTIONS;

  switch (action.type) {
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case UPDATE_ENABLE_STEPS:
      return {
        ...state,
        enabledSteps: action.payload,
      };
    case UPDATE_APPLICATION:
      return {
        ...state,
        [AssessmentSteps.APPLICATIONS]: action.payload,
      };
    case UPDATE_PRACTICES:
      return {
        ...state,
        practices: action.payload,
      };
    case UPDATE_SOIL_CHARACTERISTICS:
      return {
        ...state,
        [AssessmentSteps.SOIL_CHARACTERISTICS]: action.payload,
      };
    case UPDATE_YIELD_INFO:
      return {
        ...state,
        [AssessmentSteps.YIELD]: action.payload,
      };
    default:
      return state;
  }
};
