import { SearchOutlined } from "@ant-design/icons";
import { Input, Popover } from "antd";
import { debounce } from "lodash";
import {
  useState,
  type ChangeEventHandler,
  type Dispatch,
  type FC,
  type MouseEventHandler,
  type ReactElement,
  type SetStateAction,
  type KeyboardEventHandler,
} from "react";
import { useTranslation } from "react-i18next";
interface ContentProps {
  onChangeHandler: (id: string) => void;
  totalItems: any[];
  currentItemId: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  filteredItems: any[];
  setFilteredItems: Dispatch<SetStateAction<any[]>>;
}
interface PopoverElement {
  children: ReactElement;
}
const PopoverForSearch: FC<
  PopoverElement &
    Pick<ContentProps, "onChangeHandler" | "currentItemId" | "totalItems">
> = ({ children, ...props }): ReactElement => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<any[]>(
    props.totalItems ?? []
  );
  const onChangeHandler = (id: string): void => {
    props.onChangeHandler(id);
    setOpen(false);
    setSearchTerm("");
    setFilteredItems(props.totalItems);
  };
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
    setSearchTerm("");
    setFilteredItems(props.totalItems);
  };

  return (
    <Popover
      overlayClassName="popover-overlay"
      data-id=""
      content={
        <div>
          <Content
            currentItemId={props.currentItemId}
            filteredItems={filteredItems}
            onChangeHandler={onChangeHandler}
            searchTerm={searchTerm}
            setFilteredItems={setFilteredItems}
            setSearchTerm={setSearchTerm}
            totalItems={props.totalItems}
          />
        </div>
      }
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
    >
      {children}
    </Popover>
  );
};

interface ContentProps {
  onChangeHandler: (id: string) => void;
  totalItems: any[];
  currentItemId: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  filteredItems: any[]; // initialize with total items initially.
  setFilteredItems: Dispatch<SetStateAction<any[]>>;
}
const Content: FC<ContentProps> = ({
  onChangeHandler,
  totalItems,
  currentItemId,
  searchTerm,
  setSearchTerm,
  filteredItems,
  setFilteredItems,
}): ReactElement => {
  const { t } = useTranslation();

  const performSearch = (term: string): void => {
    const serchedOrgs = totalItems.filter((org: any) => {
      const name: string = org.name;
      return name.toLocaleLowerCase().includes(term.toLowerCase());
    });
    setFilteredItems(serchedOrgs);
  };
  const debouncedSearch = debounce(performSearch, 200);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ): void => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  const onSelectOrg: MouseEventHandler<HTMLButtonElement> = (event): void => {
    const orgElement = event.target as HTMLElement;
    const id = orgElement.id;
    if (!id) return;
    setSearchTerm("");
    setFilteredItems(totalItems);
    onChangeHandler(id);
  };
  const onSelectOrgForKeyboard: KeyboardEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    if (event.key === "Enter" || event.key === " ") {
      const orgElement = event.target as HTMLElement;
      const id = orgElement.id;
      if (!id) return;
      setSearchTerm("");
      setFilteredItems(totalItems);
      onChangeHandler(id);
    }
  };
  const emtyStateMsg =
    totalItems.length === 0
      ? "No organizations created. Tap Create new organization to create one."
      : "data not found";

  return (
    <div>
      <Input
        className="search-box"
        placeholder="Search"
        size="large"
        onChange={handleInputChange}
        value={searchTerm}
        prefix={<SearchOutlined />}
      />
      <button
        className="select-org-btn"
        onClick={onSelectOrg}
        onKeyDown={onSelectOrgForKeyboard}
      >
        {filteredItems.length === 0 && (
          <div className="empty-message">{t(emtyStateMsg)}</div>
        )}
        {filteredItems.map(({ name, id }) => (
          <li id={id} key={id} className={id === currentItemId ? "active" : ""}>
            {t(name)}
          </li>
        ))}
      </button>
    </div>
  );
};

export default PopoverForSearch;
