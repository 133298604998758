import { Table, Tooltip } from "antd";
import { type ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonType,
  Col,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import { ReactComponent as CopyIcon } from "../../assets/images/copyIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/deleteIconAssesment.svg";
import { ReactComponent as EditOutlined } from "../../assets/images/edit.svg";
import { AssessmentStepsArray } from "../../constants/Assesments";
import { GlobalContext } from "../../context/AppContextProvider";
import { FETCH_ASSESSMENT_BY_ID_SUCCESS } from "../../context/actions/ActionTypes";
import { fetchAssessmentById } from "../../context/actions/Assessment";
import {
  DeleteConfirmationPopup,
  NotificationType,
  openNotificationWithIcon,
} from "../../shared/components/ConfirmationsAndNotifiCations";
import { ErrorDisplay } from "../../shared/components/Error";
import { Loader } from "../../shared/components/Loader";
import {
  StatusIcon,
  TitleAndSubTitle,
} from "../../shared/components/Typography";
import { type AssesmentStatus } from "../../shared/components/types";
import AxiosInstance from "../../shared/utils/axios";
import { goToStepPage } from "../commonMethods";
import FormsAndFieldsMenu from "./FarmsAndFieldsMenu";
import {
  AssessmentStatuses,
  type AssessmentType,
  type PaginatedAssesments,
  type Pagination,
} from "./types";
import _ from "lodash";

export const Assessments = (): ReactElement => {
  const navigate = useNavigate();
  const { orgId = "" } = useParams();
  const {
    assessmentStepsDispatch,
    appGenericMetadata,
    setAppGenericMetadata,
    assessmentDispatch,
  } = useContext(GlobalContext);
  const [pageInfo, setPageInfo] = useState<Pagination>({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortType: "desc",
  });
  const [assessmentsData, setAssessmentsData] = useState<PaginatedAssesments>({
    assessments: [],
    totalDocs: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const onCreateAssessment = (): any => {
    navigate(`/app/organization/${orgId}/assessment/create/custom/basic-info`);
  };
  const { t } = useTranslation();

  const handleEditClick = async (assessmentId: string): Promise<any> => {
    setLoading(true);
    await fetchAssessmentById(assessmentDispatch, assessmentId)
      .then(({ data }) => {
        setLoading(true);
        const currentPage: string = data.currentPage;
        const stepPageIndex = AssessmentStepsArray.findIndex(
          (pageName) => pageName === currentPage
        );
        const url = `/app/organization/${orgId}/assessment/edit/${assessmentId}/custom/${currentPage}?isNewAssessment=false`;
        goToStepPage({
          navigate,
          assessmentStepsDispatch,
          url,
          activeStep: stepPageIndex,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const generatePDFHandler = (assessmentId: string): void => {
    setLoading(true);
    AxiosInstance.get(`/api/pdf-report?assessmentId=${assessmentId}`)
      .then(({ data }) => {
        setLoading(false);
        const PDFurl: string = data.url;
        window.open(PDFurl, "_blank");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const copyHandler = (id: string): void => {
    AxiosInstance.post(`/api/assessments/${id}/copy`)
      .then(({ data }) => {
        setLoading(false);
        const newAssessmentId: string = data.assessmentId;
        const url = `/app/organization/${orgId}/assessment/edit/${newAssessmentId}/custom/basic-info?isNewAssessment=false`;
        openNotificationWithIcon({
          type: NotificationType.SUCCESS,
          message: "Assessment copied successfully!",
        });
        goToStepPage({
          navigate,
          assessmentStepsDispatch,
          url,
          activeStep: 0,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const columnsDef: ColumnsType<AssessmentType> = [
    {
      title: t("Provider"),
      dataIndex: "provider",
      showSorterTooltip: false,
      sorter: false,
      render: (providers: string[]) => providers.join(", "),
    },
    {
      title: t("Field Name"),
      dataIndex: "cropName",
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: t("Crop Cycle"),
      dataIndex: "cropCycleName",
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: t("Created on"),
      dataIndex: "createdAt",
      showSorterTooltip: false,
      sorter: true,
      render: (dateString: string) => {
        const formattedDate = dayjs(dateString).format("DD/MM/YYYY");
        return formattedDate;
      },
    },
    {
      title: t("Status"),
      dataIndex: "assessmentStatus",
      showSorterTooltip: false,
      sorter: true,
      render: (status: AssesmentStatus, record: any) => {
        return (
          <span>
            {status === "error" ? (
              <Tooltip
                title={record.genericData?.error?.message}
                placement="bottomLeft"
              >
                <StatusIcon status={status} />
                &nbsp;&nbsp;
              </Tooltip>
            ) : (
              <>
                <StatusIcon status={status} />
                &nbsp;&nbsp;
              </>
            )}
            {AssessmentStatuses[status]}
          </span>
        );
      },
    },
    {
      title: t("Report"),
      dataIndex: "report",
      render: (_report: any, { assessmentStatus, assessmentId }: any) => {
        return assessmentStatus === "completed" ? (
          <Button
            onClick={() => {
              generatePDFHandler(assessmentId);
            }}
            type={ButtonType.link}
            color="blue"
            style={{
              cursor: "pointer",
              padding: "0",
              color: "#0092E4",
              lineHeight: 0,
            }}
          >
            {t("Open")}
          </Button>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: t("Actions"),
      dataIndex: "assessmentId",
      width: 100,
      render: (_action: string, { assessmentStatus, assessmentId }: any) => {
        const pendingStatuses = ["initiated", "inDraft"];
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {pendingStatuses.includes(assessmentStatus) ? (
              <EditOutlined
                id="edit-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  void handleEditClick(assessmentId);
                }}
              />
            ) : (
              <span style={{ padding: "0 6px" }}>&nbsp;</span>
            )}
            <CopyIcon
              id="copy-icon"
              style={{ cursor: "pointer" }}
              onClick={_.debounce(() => {
                copyHandler(assessmentId);
              }, 1000)}
            />
            <DeleteIcon
              id="delete-icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                void deleteHandler(assessmentId);
              }}
            />
          </div>
        );
      },
    },
  ];

  const getAllAssessmets = async (): Promise<void> => {
    setLoading(true);
    const { limit, page, sortBy, sortType } = pageInfo;
    const requestURL = `/api/assessments/list?orgId=${orgId}&page=${page}&limit=${limit}&sortType=${sortType}&sortBy=${sortBy}`;
    const payload = {
      cropCycleIds: [],
      status: [],
    };
    await AxiosInstance.post(requestURL, payload)
      .then(({ data }) => {
        setAssessmentsData({
          assessments: data.docs,
          totalDocs: data.totalDocs,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const checkLastRowDeletionOfPage = (): void => {
    if (assessmentsData.assessments.length === 1) {
      setPageInfo((state) => {
        return { ...state, page: state.page > 1 ? state.page - 1 : 1 };
      });
    } else {
      void getAllAssessmets();
    }
  };

  const deleteHandler = async (assesmentId: string): Promise<void> => {
    const handleDeleteConfirmation = (): void => {
      setLoading(true);
      AxiosInstance.delete(`/api/assessments/${assesmentId}`)
        .then(() => {
          checkLastRowDeletionOfPage();
          setLoading(false);
          openNotificationWithIcon({
            type: NotificationType.SUCCESS,
            message: "Assessment deleted successfully!",
          });
        })
        .catch((error) => {
          setLoading(false);
          openNotificationWithIcon({
            type: NotificationType.ERROR,
            message: "Error deleting assessment",
            description:
              "An error occurred while deleting the assessment. Please try again.",
          });
          console.error(error);
        });
    };

    DeleteConfirmationPopup({
      title: "Do you want to delete this assessment?",
      content: "This action cannot be undone.",
      onOk: handleDeleteConfirmation,
      okText: "Delete",
      cancelText: "Go Back",
    });
  };
  useEffect(() => {
    assessmentDispatch({
      type: FETCH_ASSESSMENT_BY_ID_SUCCESS,
      payload: {},
    });
  }, []);

  const getAllPageGenericMetaData = async (orgId: string): Promise<void> => {
    if (orgId) {
      if (!appGenericMetadata?.has("providers")) {
        const response = await AxiosInstance.get("/api/providers");
        setAppGenericMetadata?.(
          appGenericMetadata.set("providers", response.data)
        );
      }
      if (!appGenericMetadata?.has("applications")) {
        const response = await AxiosInstance.get(
          "/api/generic-metadata?page=applications"
        );
        setAppGenericMetadata?.(
          appGenericMetadata.set("applications", response.data)
        );
      }
      if (!appGenericMetadata?.has("practices")) {
        const response1 = await AxiosInstance.get(
          "/api/generic-metadata?page=practices"
        );
        setAppGenericMetadata?.(
          appGenericMetadata.set("practices", response1.data)
        );
      }
      if (!appGenericMetadata?.has("soil-characteristics")) {
        const response2 = await AxiosInstance.get(
          "/api/generic-metadata?page=soil-characteristics"
        );
        setAppGenericMetadata?.(
          appGenericMetadata.set("soil-characteristics", response2.data)
        );
      }
      if (!appGenericMetadata?.has("yield")) {
        const response2 = await AxiosInstance.get(
          "/api/generic-metadata?page=yield"
        );
        setAppGenericMetadata?.(
          appGenericMetadata.set("yield", response2.data)
        );
      }
    }
  };

  useEffect(() => {
    if (orgId !== null && orgId !== undefined) {
      void getAllAssessmets();
      void getAllPageGenericMetaData(orgId);
    }
  }, [orgId, pageInfo]);

  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: any
  ): void => {
    setPageInfo((state) => {
      if (!sorter.field) return state;
      return {
        ...state,
        sortBy: sorter.field,
        sortType: sorter.order === "descend" ? "desc" : "asc",
      };
    });
  };
  const paginationChangeHandler = (page: number, _pageSize: number): void => {
    setPageInfo((state) => {
      return { ...state, page };
    });
  };

  return (
    <>
      {loading && <Loader mask />}

      <Row gutter={16}>
        {assessmentsData.assessments.length === 0 &&
        assessmentsData.totalDocs === 0 ? (
          <ErrorDisplay
            title="No assessments yet"
            subTitle={
              "Create a new assessment for you to be\nable to review your reports."
            }
            cardClassName="custom-card create-new-asmt-btn"
          >
            <Button
              type={ButtonType.primary}
              onClick={onCreateAssessment}
              style={{
                display: "flex",
                margin: "0 auto",
                cursor: "pointer",
              }}
            >
              {t("Create new assessment")}
            </Button>
          </ErrorDisplay>
        ) : (
          <Col
            sm={24}
            md={18}
            lg={19}
            style={{
              background: "#FFFFFF",
              padding: "24px",
              borderRadius: "8px",
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
            }}
          >
            <div className="assesmets">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px 0",
                }}
              >
                {/* <FilterMenu /> */}
                {/* <div /> */}
                <TitleAndSubTitle titleFontSize={20} title="My assessments" />
                <Button
                  type={ButtonType.primary}
                  onClick={onCreateAssessment}
                  style={{ cursor: "pointer" }}
                >
                  {t("Create new assessment")}
                </Button>
              </div>

              <Table
                pagination={{
                  position: ["bottomCenter"],
                  total: assessmentsData.totalDocs,
                  hideOnSinglePage: true,
                  pageSize: pageInfo.limit,
                  onChange: paginationChangeHandler,
                  showSizeChanger: false,
                }}
                onChange={handleTableChange}
                columns={columnsDef}
                dataSource={assessmentsData.assessments}
                className="custom-table"
              />
            </div>
          </Col>
        )}
        <Col sm={24} md={6} lg={5} style={{ paddingLeft: "16px" }}>
          <FormsAndFieldsMenu />
        </Col>
      </Row>
    </>
  );
};
