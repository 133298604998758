export enum AssessmentSteps {
  BASIC_INFO = "basic-info",
  APPLICATIONS = "applications",
  PRACTICES = "practices",
  SOIL_CHARACTERISTICS = "soil-characteristics",
  YIELD = "yield",
}
export const AssessmentStepsArray = Object.values(AssessmentSteps);
export const Application = {
  FERTILIZER_PRODUCTS: "fertilizer_products",
  FERTILIZER_APPLICATIONS: "fertilizer_applications",
  CROP_PROTECTION: "crop_protection",
  IRRIGATION: "irrigation_information",
  YIELD: "yield",
  SEED_PRODUCTS: "seed_products",
};
export const PracticesKeys = {
  RESIDUE: "residue",
  CO_PRODUCT: "co_product",
  LAND_MANAGEMENT: "land_management",
  CONSERVATION_PRACTICES: "conservation_practices",
  TRANSPORT: "transport",
  MACHINERY_USAGE: "Machinery usage",
  NUTRIENT: "nutrient",
  CARBON_AND_SEQUESTRATION: "carbon_and_sequestration",
};
export const SoilCharacteristicsKeys = {
  RESIDUE: "residue",
};
export const YieldKeys = {
  YIELD: "yield",
};
