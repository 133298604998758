/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { isEmpty } from "lodash";
import { ASSESMENT_STEPS_ACTIONS } from "../context/actions/ActionTypes";
import { type ActionType } from "../context/reducers/assessmentStepsReducer";
import { type Options } from "./assessments/custom/practices/editModelColumns";

export const goToStepPage = ({
  navigate,
  assessmentStepsDispatch,
  url,
  activeStep,
}: {
  navigate: any;
  assessmentStepsDispatch: (value: ActionType) => void;
  url: string;
  activeStep: number;
}): void => {
  assessmentStepsDispatch({
    type: ASSESMENT_STEPS_ACTIONS.UPDATE_CURRENT_STEP,
    payload: activeStep,
  });
  navigate(url);
};

export const getLabelNameFromColumn = (
  fieldId: string,
  // type for id is number | string | undefined | any
  id: any,
  columns: any,
  sectionName: string
): string => {
  if (!id || isEmpty(columns)) return "";
  const columnField = columns[sectionName]?.find(
    (field: any) => field.fieldId === fieldId
  );
  const res = columnField?.options?.find((option: Options) => option.id === id);
  return res?.name ?? "";
};

export const getLabelName = (
  field: string,
  id: number | undefined | string,
  metaData: any
): string => {
  if (!id || isEmpty(metaData)) return "-";
  const res = metaData[field]?.find((option: Options) => option.id === id);
  return res?.name ?? "";
};

export const getDynamicOptionsFromMetaDataBasedOnFertilizerType = (
  metaData: any,
  optionsFieldName: string,
  fertilizerType: string
): any => {
  if (!fertilizerType) {
    return null;
  }
  const fertilizerTypeData: any = metaData.fertilizerTypes.find(
    ({ id }: any) => id === fertilizerType
  );
  const optionList = fertilizerTypeData[optionsFieldName].map((item: any) => ({
    id: item,
    name: item,
  }));
  return optionList;
};
interface GetPathIds {
  organizationID: string;
  assessmentID: string | null;
}
export const getPathIds = (pathname: string): GetPathIds => {
  const organizationID = pathname.split("/")[3];
  if (pathname.includes("assessment/edit")) {
    const assessmentID = pathname.split("/")[6];
    return { organizationID, assessmentID };
  }
  return { organizationID, assessmentID: null };
};
