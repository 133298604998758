import {
  CheckCircleFilled,
  InfoCircleFilled,
  MinusCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "syngenta-digital-cropwise-react-ui-kit";
import "./styles.less";
import {
  type AssesmentStatus,
  type StatusIconProps,
  type TitleAndSubTitleProps,
  type TitleNameProps,
} from "./types";

export const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  const icons: Record<AssesmentStatus, ReactElement<SVGElement>> = {
    completed: <CheckCircleFilled style={{ color: "#009933" }} />,
    error: <InfoCircleFilled style={{ color: "#CF3537" }} />,
    inDraft: <MinusCircleFilled style={{ color: "#F0C355" }} />,
    initiated: <MinusCircleFilled style={{ color: "#F0C355" }} />,
    inprogress: <MinusCircleFilled style={{ color: "#F0C355" }} />,
  };
  return icons[status];
};

const { Text, Title } = Typography;
const TitleName: FC<TitleNameProps> = ({
  title,
  titleFontSize = 20,
  statusIcon,
  showToolTip,
  tooltipMsg,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex" }}>
      <div className="title_section__title">
        <Title strong style={{ fontSize: titleFontSize, margin: "0 5px 0 0" }}>
          {t(title as string)}
        </Title>
        {Boolean(statusIcon) && (
          <StatusIcon status={statusIcon ?? "inprogress"} />
        )}
      </div>
      <span>
        {Boolean(showToolTip) && (
          <Tooltip
            placement="bottomLeft"
            style={{ marginLeft: "-15px" }}
            title={tooltipMsg}
          >
            <QuestionCircleFilled style={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export const TitleAndSubTitle: FC<TitleAndSubTitleProps> = ({
  subTitle,
  title,
  className = "",
  titleFontSize = 16,
  statusIcon,
  tooltipMsg,
  showSubtitleFirst = true,
  subTitleFontSize = 14,
  style,
  showToolTip = false,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <div style={style} className={`title_section ${className}`}>
      {!showSubtitleFirst && (
        <TitleName
          statusIcon={statusIcon}
          title={title}
          titleFontSize={titleFontSize}
        />
      )}
      {Boolean(subTitle) && (
        <>
          <Text
            style={{ fontSize: subTitleFontSize }}
            className="title_section__subTitle"
          >
            {t(subTitle as string)}
          </Text>
          {Boolean(tooltipMsg) && (
            <Tooltip
              placement="bottomLeft"
              style={{ marginLeft: "-15px" }}
              title={tooltipMsg}
            >
              <QuestionCircleFilled style={{ fontSize: 20 }} />
            </Tooltip>
          )}
        </>
      )}
      {showSubtitleFirst && (
        <TitleName
          statusIcon={statusIcon}
          title={title}
          titleFontSize={titleFontSize}
          showToolTip={showToolTip}
          tooltipMsg={tooltipMsg}
        />
      )}
    </div>
  );
};
