import { type FC, type ReactElement, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Card, Result } from "syngenta-digital-cropwise-react-ui-kit";
import error from "../../assets/images/error.svg";
import { PageLayout } from "../layout/PageLayout";
interface IErrorDisplay {
  title: string;
  subTitle?: string;
  cardClassName?: string;
  children?: ReactNode;
  sourceImg?: string | null;
}
export const ErrorDisplay: FC<IErrorDisplay> = ({
  title,
  subTitle,
  cardClassName,
  children,
  sourceImg,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Card className={cardClassName ?? "height-full"}>
        <Result
          title={t(title)}
          subTitle={t(subTitle ?? "")}
          icon={<img src={sourceImg ?? error} alt="" />}
        ></Result>
        {children}
      </Card>
    </PageLayout>
  );
};
