import { Steps, type StepProps } from "antd";
import { isEmpty } from "lodash";
import {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useState,
  type ReactElement,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as FinishedIcon } from "../../../assets/images/check-circle-outline.svg";
import { ReactComponent as BasicInfoIcon } from "../../../assets/images/edit_note.svg";
import { ReactComponent as ApplicationsIcon } from "../../../assets/images/fertilizer.svg";
import { ReactComponent as YieldIcon } from "../../../assets/images/form-practices.svg";
import { ReactComponent as PracticesIcon } from "../../../assets/images/landscape.svg";
import { ReactComponent as Siol } from "../../../assets/images/soil.svg";
import { AssessmentStepsArray } from "../../../constants/Assesments";
import { GlobalContext } from "../../../context/AppContextProvider";
import { ASSESMENT_STEPS_ACTIONS } from "../../../context/actions/ActionTypes";
import { type IAssessmentReducer } from "../../../context/reducers/assessments";
import { Loader } from "../../../shared/components/Loader";
import { TitleAndSubTitle } from "../../../shared/components/Typography";
import "./styles.less";
const Applications = lazy(
  async () => await import("./applications/Applications")
);
const BasicInfo = lazy(async () => await import("./basic-info/BasicInfo"));
const Practices = lazy(async () => await import("./practices/Practices"));
const SoilCharecteristics = lazy(async () => await import("./soil/Soil"));
const Yield = lazy(async () => await import("./yield/Yield"));

const StepComponent = (): ReactElement => {
  const { assessmentStepsDispatch, assessmentStepsState, assessmentState } =
    useContext(GlobalContext);
  const { currentStep, enabledSteps } = assessmentStepsState;

  const { customStep, assessmentId = "", orgId = "" } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Create a new custom assessment");
  useEffect(() => {
    if (customStep != null) {
      let currentStepIndex = AssessmentStepsArray.findIndex(
        (step) => customStep === step
      );
      currentStepIndex = currentStepIndex > -1 ? currentStepIndex : 0;
      assessmentStepsDispatch({
        type: ASSESMENT_STEPS_ACTIONS.UPDATE_CURRENT_STEP,
        payload: currentStepIndex,
      });
    }
    if (window.location.pathname.includes("edit")) {
      setTitle("Edit Assessment");
    } else if (window.location.pathname.includes("create")) {
      setTitle("Create a new custom assessment");
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(assessmentState?.currentAssessment)) {
      const {
        applications,
        practices,
        soilCharacteristics,
        yield: yieldData,
      }: any = assessmentState?.currentAssessment as IAssessmentReducer;
      /**
       * @constant:isDataAvailalable, old assessments may not have country and city so we are enabling only if the data exists
       *  */
      const isDataAvailalable =
        assessmentState?.currentAssessment.farms.country;
      const setEnableSteps = {
        applications: Boolean(applications && isDataAvailalable),
        practices: Boolean(practices && isDataAvailalable),
        soilCharacteristics: Boolean(soilCharacteristics && isDataAvailalable),
        yield: Boolean(yieldData && isDataAvailalable),
      };
      assessmentStepsDispatch({
        type: ASSESMENT_STEPS_ACTIONS.UPDATE_ENABLE_STEPS,
        payload: setEnableSteps,
      });
    }
  }, [assessmentState?.currentAssessment]);
  const handleStepChange = (current: number): void => {
    const customStep = AssessmentStepsArray[current];
    const url =
      assessmentId != null
        ? `edit/${assessmentId}/custom/${customStep}`
        : `create/custom/${customStep}`;
    navigate(`/app/organization/${orgId}/assessment/${url}`);
    assessmentStepsDispatch({
      type: ASSESMENT_STEPS_ACTIONS.UPDATE_CURRENT_STEP,
      payload: current,
    });
  };

  const stepitems: StepProps[] = [
    {
      title: "Basic info",
      icon: currentStep > 0 ? <FinishedIcon /> : <BasicInfoIcon />,
      disabled: false,
    },
    {
      title: "Applications",
      icon: currentStep > 1 ? <FinishedIcon /> : <ApplicationsIcon />,
      disabled: !enabledSteps.applications,
    },
    {
      title: "Practices & machinery usage",
      icon: currentStep > 2 ? <FinishedIcon /> : <PracticesIcon />,
      disabled: !enabledSteps.practices,
    },

    {
      title: "Soil characteristics",
      icon: currentStep > 3 ? <FinishedIcon /> : <Siol />,
      disabled: !enabledSteps.soilCharacteristics,
    },
    {
      title: "Yield",
      icon: <YieldIcon />,
      disabled: !enabledSteps.yield,
    },
  ];

  const stepComponents: ReactElement[] = [
    <BasicInfo key={0} />,
    <Applications key={1} />,
    <Practices key={2} />,
    <SoilCharecteristics key={3} />,
    <Yield key={4} />,
  ];

  return (
    <div className="custom">
      <TitleAndSubTitle titleFontSize={20} title={title} />
      <Steps
        items={stepitems}
        className="custom__steps"
        labelPlacement="vertical"
        current={currentStep}
        onChange={handleStepChange}
      />
      <Suspense fallback={<Loader mask />}>
        {stepComponents[currentStep]}
      </Suspense>
    </div>
  );
};

export default StepComponent;
