import { type FC } from "react";
import { ErrorDisplay } from "../../shared/components/Error";
import { ContentLoader } from "../../shared/layout/ContentLoader";

const NotFound: FC = (): JSX.Element => {
  return (
    <ContentLoader>
      <ErrorDisplay
        title="Page not found"
        subTitle="The page you are looking for does not exist."
      />
    </ContentLoader>
  );
};

export default NotFound;
