import {
  FETCH_FARMS_AND_FIELDS_LOADING,
  FETCH_FARMS_AND_FIELDS_SUCCESS,
  FETCH_FARMS_AND_FIELDS_ERROR,
} from "../actions/ActionTypes";
export interface FarmsAndFieldsReducerInterface {
  farms: any[];
  isFetchingFarms: boolean;
  farmsError: object;
}

export const farmsAndFieldsReducer = (
  state: FarmsAndFieldsReducerInterface,
  action: any
): any => {
  switch (action.type) {
    case FETCH_FARMS_AND_FIELDS_LOADING:
      return {
        ...state,
        isFetchingFarms: true,
      };
    case FETCH_FARMS_AND_FIELDS_SUCCESS:
      return {
        ...state,
        isFetchingFarms: false,
        farms: action.payload,
      };
    case FETCH_FARMS_AND_FIELDS_ERROR: {
      return {
        ...state,
        isFetchingFarms: false,
        farmsError: action.error,
      };
    }
    default:
  }
};
