export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_ERROR = "FETCH_ORGANIZATIONS_ERROR";
export const FETCH_ORGANIZATIONS_LOADING = "FETCH_ORGANIZATIONS_LOADING";

export const SET_CURRENT_ORG = "SET_CURRENT_ORG";

export enum ASSESMENT_STEPS_ACTIONS {
  UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP",
  UPDATE_ENABLE_STEPS = "UPDATE_ENABLE_STEPS",
  UPDATE_APPLICATION = "UPDATE_APPLICATION",
  UPDATE_SOIL_CHARACTERISTICS = "UPDATE_SOIL_CHARACTERISTICS",
  UPDATE_PRACTICES = "UPDATE_PRACTICES",
  UPDATE_YIELD_INFO = "UPDATE_YIELD_INFO",
}
export const FETCH_FARMS_AND_FIELDS_SUCCESS = "FETCH_FARMS_AND_FIELDS_SUCCESS";
export const FETCH_FARMS_AND_FIELDS_ERROR = "FETCH_FARMS_AND_FIELDS_ERROR";
export const FETCH_FARMS_AND_FIELDS_LOADING = "FETCH_FARMS_AND_FIELDS_LOADING";

export const FETCH_ASSESSMENT_BY_ID_SUCCESS = "FETCH_ASSESSMENT_BY_ID_SUCCESS";
export const FETCH_ASSESSMENT_BY_ID_ERROR = "FETCH_ASSESSMENT_BY_ID_ERROR";
export const FETCH_ASSESSMENT_BY_ID_LOADING = "FETCH_ASSESSMENT_BY_ID_LOADING";
