import { type Dispatch, type SetStateAction } from "react";
import AxiosInstance from "../../shared/utils/axios";
import {
  FETCH_ASSESSMENT_BY_ID_ERROR,
  FETCH_ASSESSMENT_BY_ID_LOADING,
  FETCH_ASSESSMENT_BY_ID_SUCCESS,
} from "./ActionTypes";

export const fetchAssessmentById = async (
  dispatch: Dispatch<SetStateAction<object>>,
  id: string
): Promise<any> => {
  dispatch({ type: FETCH_ASSESSMENT_BY_ID_LOADING });
  try {
    const response = await AxiosInstance.get(`/api/assessments/${id}`);
    dispatch({
      type: FETCH_ASSESSMENT_BY_ID_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({ type: FETCH_ASSESSMENT_BY_ID_ERROR, error });
  }
};
